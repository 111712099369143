<template>
  <b-modal
    id="modal-deposit-withdraw"
    cancel-variant="outline-secondary"
    :ok-title="$t('button.save')"
    :cancel-title="$t('button.close')"
    centered
    :title="modalTitle"
    @ok="handleOk"
  >
    <validation-observer ref="depositWithdrawForm" #default="{ invalid }">
      <!-- form -->
      <n-form-confirmation
        ref="formConfirmation"
        key="depositWithdrawForm"
        :form="$refs.depositWithdrawForm"
        :disabled="invalid"
        @submit="submit"
      >
        <n-input :fields="formInput" v-model="data"></n-input>
      </n-form-confirmation>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NFormConfirmation from "@/components/NFormConfirmation";
import { currencyFormat } from "@/libs/helper.js";

const AccountRepository = Repository.get("account");

export default {
  components: {
    BModal,
    BSpinner,
    NFormConfirmation,
    NInput,
  },
  watch: {
    "data.type": function (value) {
      this.updateTitle(value);
      return;
    },
  },
  data() {
    return {
      loading: false,
      data: {
        type: "deposit",
        amount: 0,
        userId: null,
        remark: null,
        username: null,
        ccy: null,
        availableCredit: 0,
      },
      modalTitle: this.$t("field.deposit"),
    };
  },
  methods: {
    updateTitle(value) {
      const username = ` (${this.data.username})`;
      const balance = this.data.availableCredit
        ? "   -  " +
          "Available Credit: " +
          currencyFormat(this.data.availableCredit, this.data.ccy)
        : "";
      if (value === "deposit") {
        this.modalTitle = this.$t("field.deposit") + username + balance;
      } else {
        this.modalTitle = this.$t("field.withdraw") + username + balance;
      }
    },
    show(modalTitle = null) {
      if (modalTitle) {
        this.modalTitle = modalTitle;
      }
      if (
        this.$store.state.profile.avalBalance &&
        this.$store.state.profile.accountccy
      ) {
        this.avalBalance = this.$store.state.profile.avalBalance;
        this.accountccy = this.$store.state.profile.accountccy;
      }
      this.updateTitle(this.data.type);
      this.$bvModal.show("modal-deposit-withdraw");
    },
    hide() {
      this.$bvModal.hide("modal-deposit-withdraw");
    },
    changeData(data) {
      this.data = {
        ...data,
      };
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.depositWithdrawForm.validate().then((success) => {
        if (success) {
          this.$refs.formConfirmation.confirm();
        }
      });
    },
    submit() {
      this.loading = true;
      AccountRepository.depositOrWithdraw(this.data, this.data.type)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.depositWithdrawForm.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
